@import url("https://fonts.googleapis.com/css?family=Roboto:400,500");
/*! calendar material theme variables */
/*! component icons */
.e-calendar .e-header .e-date-icon-prev::before {
  content: '\e910';
}

.e-calendar .e-header .e-date-icon-next::before {
  content: '\e916';
}

/*! calendar layout */
ejs-calendar {
  display: block;
}

.e-calendar.e-disabled .e-header .e-prev,
.e-calendar.e-disabled .e-header .e-next {
  cursor: default;
  pointer-events: none;
  -ms-touch-action: none;
      touch-action: none;
}

.e-calendar.e-disabled .e-header .e-title {
  cursor: default;
  pointer-events: none;
  -ms-touch-action: none;
      touch-action: none;
}

.e-calendar.e-disabled .e-content td {
  pointer-events: none;
  -ms-touch-action: none;
      touch-action: none;
}

.e-calendar.e-disabled .e-btn.e-today {
  pointer-events: none;
  -ms-touch-action: none;
      touch-action: none;
}

.e-calendar,
.e-bigger.e-small .e-calendar {
  -webkit-tap-highlight-color: transparent;
  border-radius: 2px;
  display: block;
  overflow: auto;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.e-calendar.e-rtl .e-header .e-title,
.e-bigger.e-small .e-calendar.e-rtl .e-header .e-title {
  float: right;
  text-align: right;
}

.e-calendar.e-rtl .e-header .e-icon-container,
.e-bigger.e-small .e-calendar.e-rtl .e-header .e-icon-container {
  float: left;
}

.e-calendar .e-header,
.e-bigger.e-small .e-calendar .e-header {
  background: none;
  display: table;
  font-weight: 500;
  position: relative;
  text-align: center;
  width: 100%;
}

.e-calendar .e-header button,
.e-bigger.e-small .e-calendar .e-header button {
  background: transparent;
  border: 0;
  margin-right: 0;
  padding: 0;
  text-decoration: none;
}

.e-calendar .e-header span,
.e-bigger.e-small .e-calendar .e-header span {
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 500;
  line-height: 16px;
  padding: 10px;
  vertical-align: middle;
}

.e-calendar .e-header span.e-disabled,
.e-bigger.e-small .e-calendar .e-header span.e-disabled {
  cursor: default;
}

.e-calendar .e-week-header,
.e-bigger.e-small .e-calendar .e-week-header {
  padding: 0;
}

.e-calendar th,
.e-bigger.e-small .e-calendar th {
  cursor: default;
  font-size: 13px;
  font-weight: normal;
  text-align: center;
}

.e-calendar .e-content .e-selected,
.e-calendar .e-content .e-state-hover,
.e-bigger.e-small .e-calendar .e-content .e-selected,
.e-bigger.e-small .e-calendar .e-content .e-state-hover {
  border-radius: 0;
}

.e-calendar .e-content span.e-day,
.e-bigger.e-small .e-calendar .e-content span.e-day {
  border-radius: 0;
  cursor: pointer;
  display: inline-block;
  font-size: 13px;
  overflow: hidden;
  padding: 0;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
}

.e-calendar .e-content th,
.e-calendar .e-content td,
.e-bigger.e-small .e-calendar .e-content th,
.e-bigger.e-small .e-calendar .e-content td {
  box-sizing: border-box;
}

.e-calendar .e-content td.e-disabled,
.e-bigger.e-small .e-calendar .e-content td.e-disabled {
  opacity: 0.35;
  pointer-events: none;
  -ms-touch-action: none;
      touch-action: none;
}

.e-calendar .e-content td,
.e-bigger.e-small .e-calendar .e-content td {
  cursor: pointer;
  padding: 2px;
  text-align: center;
}

.e-calendar .e-content td.e-week-number,
.e-bigger.e-small .e-calendar .e-content td.e-week-number {
  color: rgba(0, 0, 0, 0.87);
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
}

.e-calendar .e-content td.e-overlay,
.e-bigger.e-small .e-calendar .e-content td.e-overlay {
  background: none;
  width: initial;
}

.e-calendar .e-content table,
.e-bigger.e-small .e-calendar .e-content table {
  border-collapse: separate;
  border-spacing: 0;
  border-width: 0;
  float: left;
  margin: 0;
  outline: 0;
  padding: 0 10px 10px;
  table-layout: fixed;
  width: 100%;
}

.e-calendar .e-content td.e-other-month > span.e-day,
.e-calendar .e-content td.e-other-year > span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-other-month > span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-other-year > span.e-day {
  display: none;
  font-weight: normal;
}

.e-calendar .e-content tr.e-month-hide,
.e-bigger.e-small .e-calendar .e-content tr.e-month-hide {
  display: none;
  font-weight: normal;
}

.e-calendar .e-content tr.e-month-hide,
.e-calendar .e-content td.e-other-month,
.e-calendar .e-content td.e-other-year,
.e-bigger.e-small .e-calendar .e-content tr.e-month-hide,
.e-bigger.e-small .e-calendar .e-content td.e-other-month,
.e-bigger.e-small .e-calendar .e-content td.e-other-year {
  pointer-events: none;
  -ms-touch-action: none;
      touch-action: none;
}

.e-calendar .e-content tr.e-month-hide,
.e-calendar .e-content td.e-other-month.e-disabled,
.e-calendar .e-content td.e-other-year.e-disabled,
.e-bigger.e-small .e-calendar .e-content tr.e-month-hide,
.e-bigger.e-small .e-calendar .e-content td.e-other-month.e-disabled,
.e-bigger.e-small .e-calendar .e-content td.e-other-year.e-disabled {
  pointer-events: none;
  -ms-touch-action: none;
      touch-action: none;
}

.e-calendar .e-content td.e-week-number:hover span.e-day,
.e-calendar .e-content td.e-week-number:hover,
.e-bigger.e-small .e-calendar .e-content td.e-week-number:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-week-number:hover {
  background-color: #fff;
  cursor: default;
}

.e-calendar .e-header .e-prev,
.e-calendar .e-header .e-next,
.e-bigger.e-small .e-calendar .e-header .e-prev,
.e-bigger.e-small .e-calendar .e-header .e-next {
  border-radius: 50%;
  display: inline-block;
  font-size: 14px;
  vertical-align: middle;
}

.e-calendar .e-header .e-title,
.e-bigger.e-small .e-calendar .e-header .e-title {
  cursor: pointer;
  display: inline-block;
  float: left;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}

.e-calendar .e-header .e-title,
.e-bigger.e-small .e-calendar .e-header .e-title {
  margin-left: 5px;
}

.e-calendar .e-header .e-prev:hover,
.e-calendar .e-header .e-next:hover,
.e-bigger.e-small .e-calendar .e-header .e-prev:hover,
.e-bigger.e-small .e-calendar .e-header .e-next:hover {
  cursor: pointer;
}

.e-calendar .e-header .e-prev.e-overlay,
.e-calendar .e-header .e-next.e-overlay,
.e-bigger.e-small .e-calendar .e-header .e-prev.e-overlay,
.e-bigger.e-small .e-calendar .e-header .e-next.e-overlay {
  background: none;
}

.e-calendar .e-header.e-decade .e-title,
.e-calendar .e-header.e-year .e-title,
.e-bigger.e-small .e-calendar .e-header.e-decade .e-title,
.e-bigger.e-small .e-calendar .e-header.e-year .e-title {
  margin-left: 5px;
}

.e-calendar .e-header.e-decade .e-title,
.e-bigger.e-small .e-calendar .e-header.e-decade .e-title {
  cursor: default;
}

.e-calendar .e-header .e-icon-container,
.e-bigger.e-small .e-calendar .e-header .e-icon-container {
  display: inline-block;
  float: right;
  padding-top: 0;
}

.e-calendar .e-footer-container,
.e-bigger.e-small .e-calendar .e-footer-container {
  text-transform: uppercase;
}

.e-calendar,
.e-bigger.e-small .e-calendar {
  max-width: 246px;
  min-width: 240px;
  padding: 0;
}

.e-calendar.e-calendar-day-header-lg,
.e-bigger.e-small .e-calendar.e-calendar-day-header-lg {
  max-width: 100%;
  min-width: 540px;
}

.e-calendar.e-week-number,
.e-bigger.e-small .e-calendar.e-week-number {
  min-width: 262px;
}

.e-calendar.e-week,
.e-bigger.e-small .e-calendar.e-week {
  max-width: 246px;
  min-width: 240px;
}

.e-calendar .e-header .e-title,
.e-bigger.e-small .e-calendar .e-header .e-title {
  line-height: 40px;
}

.e-calendar.e-rtl .e-header .e-title,
.e-bigger.e-small .e-calendar.e-rtl .e-header .e-title {
  text-align: right;
  text-indent: 4px;
}

.e-calendar .e-header,
.e-bigger.e-small .e-calendar .e-header {
  height: 40px;
}

.e-calendar .e-header.e-month,
.e-bigger.e-small .e-calendar .e-header.e-month {
  padding: 10px 10px 0 10px;
}

.e-calendar .e-header.e-year, .e-calendar .e-header.e-decade,
.e-bigger.e-small .e-calendar .e-header.e-year,
.e-bigger.e-small .e-calendar .e-header.e-decade {
  padding: 10px 10px 0 10px;
}

.e-calendar th,
.e-bigger.e-small .e-calendar th {
  font-weight: normal;
  height: 30px;
  text-transform: none;
}

.e-calendar .e-content .e-selected,
.e-calendar .e-content .e-state-hover,
.e-bigger.e-small .e-calendar .e-content .e-selected,
.e-bigger.e-small .e-calendar .e-content .e-state-hover {
  border-radius: 0;
}

.e-calendar .e-content span.e-day,
.e-bigger.e-small .e-calendar .e-content span.e-day {
  border: none;
  font-size: 13px;
  font-weight: normal;
  height: 30px;
  line-height: 30px;
  width: 30px;
}

.e-calendar .e-content.e-year table,
.e-calendar .e-content.e-decade table,
.e-bigger.e-small .e-calendar .e-content.e-year table,
.e-bigger.e-small .e-calendar .e-content.e-decade table {
  border-spacing: 0;
  padding: 10px;
}

.e-calendar .e-content.e-month td,
.e-bigger.e-small .e-calendar .e-content.e-month td {
  height: 30px;
  padding: 2px;
}

.e-calendar .e-content .tfooter > tr > td,
.e-bigger.e-small .e-calendar .e-content .tfooter > tr > td {
  height: 36px;
  line-height: 36px;
}

.e-calendar .e-content.e-year td,
.e-calendar .e-content.e-decade td,
.e-bigger.e-small .e-calendar .e-content.e-year td,
.e-bigger.e-small .e-calendar .e-content.e-decade td {
  height: 45px;
  padding: 6px;
}

.e-calendar .e-content.e-year td > span.e-day,
.e-calendar .e-content.e-decade td > span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-year td > span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-decade td > span.e-day {
  font-weight: normal;
  height: 45px;
  line-height: 45px;
  width: 45px;
}

.e-calendar .e-header .e-icon-container .e-prev,
.e-calendar .e-header .e-icon-container .e-next,
.e-bigger.e-small .e-calendar .e-header .e-icon-container .e-prev,
.e-bigger.e-small .e-calendar .e-header .e-icon-container .e-next {
  height: 36px;
  width: 36px;
}

.e-calendar .e-footer-container,
.e-bigger.e-small .e-calendar .e-footer-container {
  background-color: #fff;
  border-top: none;
  cursor: default;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: end;
      justify-content: flex-end;
  padding: 0 10px 10px 10px;
  text-align: center;
  width: 100%;
}

.e-small.e-bigger.e-calendar .e-content.e-year span.e-day,
.e-small.e-bigger .e-calendar .e-content.e-year span.e-day {
  font-size: 13px;
}

.e-small.e-bigger.e-calendar .e-content.e-month table,
.e-small.e-bigger .e-calendar .e-content.e-month table {
  padding: 0 10px 10px;
}

.e-bigger.e-calendar,
*.e-bigger .e-calendar {
  max-width: 296px;
  min-width: 296px;
  padding: 0;
}

.e-bigger.e-calendar.e-calendar-day-header-lg,
*.e-bigger .e-calendar.e-calendar-day-header-lg {
  max-width: 100%;
  min-width: 540px;
}

.e-bigger.e-calendar.e-week,
*.e-bigger .e-calendar.e-week {
  max-width: 296px;
  min-width: 296px;
}

.e-bigger.e-calendar.e-week-number,
*.e-bigger .e-calendar.e-week-number {
  min-width: 320px;
}

.e-bigger.e-calendar .e-header .e-title,
*.e-bigger .e-calendar .e-header .e-title {
  font-size: 16px;
  line-height: 48px;
  width: 60%;
}

.e-bigger.e-calendar.e-rtl .e-header .e-title,
*.e-bigger .e-calendar.e-rtl .e-header .e-title {
  line-height: 48px;
  text-indent: 6px;
}

.e-bigger.e-calendar .e-header,
*.e-bigger .e-calendar .e-header {
  height: 48px;
  padding: 10px 10px 0 16px;
}

.e-bigger.e-calendar .e-header button,
*.e-bigger .e-calendar .e-header button {
  margin-right: 0;
}

.e-bigger.e-calendar .e-header button span,
*.e-bigger .e-calendar .e-header button span {
  font-size: 16px;
  padding: 15px;
}

.e-bigger.e-calendar .e-header.e-year, .e-bigger.e-calendar .e-header.e-decade,
*.e-bigger .e-calendar .e-header.e-year,
*.e-bigger .e-calendar .e-header.e-decade {
  padding: 10px 10px 0 16px;
}

.e-bigger.e-calendar th,
*.e-bigger .e-calendar th {
  font-size: 14px;
  height: 36px;
  text-transform: none;
}

.e-bigger.e-calendar .e-content.e-year span.e-day,
*.e-bigger .e-calendar .e-content.e-year span.e-day {
  font-size: 13px;
  font-weight: normal;
}

.e-bigger.e-calendar .e-content.e-month table,
*.e-bigger .e-calendar .e-content.e-month table {
  padding: 0 10px 10px;
}

.e-bigger.e-calendar .e-content.e-year table,
.e-bigger.e-calendar .e-content.e-decade table,
*.e-bigger .e-calendar .e-content.e-year table,
*.e-bigger .e-calendar .e-content.e-decade table {
  padding: 0 10px 10px;
}

.e-bigger.e-calendar .e-content .e-selected,
.e-bigger.e-calendar .e-content .e-state-hover,
*.e-bigger .e-calendar .e-content .e-selected,
*.e-bigger .e-calendar .e-content .e-state-hover {
  border-radius: 0;
}

.e-bigger.e-calendar .e-content span.e-day,
*.e-bigger .e-calendar .e-content span.e-day {
  font-size: 13px;
  height: 36px;
  line-height: 36px;
  width: 36px;
}

.e-bigger.e-calendar .e-content.e-month td,
*.e-bigger .e-calendar .e-content.e-month td {
  height: 2px;
  padding: 2px;
}

.e-bigger.e-calendar .e-content.e-year td,
.e-bigger.e-calendar .e-content.e-decade td,
*.e-bigger .e-calendar .e-content.e-year td,
*.e-bigger .e-calendar .e-content.e-decade td {
  height: 60px;
  padding: 2px;
}

.e-bigger.e-calendar .e-content.e-year td > span.e-day,
.e-bigger.e-calendar .e-content.e-decade td > span.e-day,
*.e-bigger .e-calendar .e-content.e-year td > span.e-day,
*.e-bigger .e-calendar .e-content.e-decade td > span.e-day {
  height: 60px;
  line-height: 60px;
  width: 60px;
}

.e-bigger.e-calendar .e-header .e-icon-container .e-prev,
.e-bigger.e-calendar .e-header .e-icon-container .e-next,
*.e-bigger .e-calendar .e-header .e-icon-container .e-prev,
*.e-bigger .e-calendar .e-header .e-icon-container .e-next {
  height: 48px;
  width: 48px;
}

.e-bigger.e-calendar .e-footer-container,
*.e-bigger .e-calendar .e-footer-container {
  border-top: none;
  padding: 0 10px 10px 10px;
}

.e-small.e-calendar,
.e-small .e-calendar {
  max-width: 246px;
  min-width: 240px;
  padding: 0;
}

.e-small.e-calendar.e-calendar-day-header-lg,
.e-small .e-calendar.e-calendar-day-header-lg {
  max-width: 100%;
  min-width: 540px;
}

.e-small.e-calendar .e-content span.e-day,
.e-small .e-calendar .e-content span.e-day {
  font-size: 12px;
  height: 24px;
  line-height: 24px;
  width: 24px;
}

.e-small.e-calendar .e-content.e-month td,
.e-small .e-calendar .e-content.e-month td {
  height: 24px;
}

.e-small.e-calendar .e-header,
.e-small .e-calendar .e-header {
  height: 32px;
}

.e-small.e-calendar .e-header span,
.e-small .e-calendar .e-header span {
  font-size: 12px;
  padding: 6px;
}

.e-small.e-calendar .e-header .e-title,
.e-small .e-calendar .e-header .e-title {
  font-size: 13px;
  line-height: 32px;
}

.e-small.e-calendar .e-header .e-icon-container .e-prev,
.e-small.e-calendar .e-header .e-icon-container .e-next,
.e-small .e-calendar .e-header .e-icon-container .e-prev,
.e-small .e-calendar .e-header .e-icon-container .e-next {
  height: 28px;
  width: 28px;
}

.e-small.e-calendar th,
.e-small .e-calendar th {
  font-size: 12px;
  height: 24px;
}

.e-calendar .e-btn.e-today.e-flat.e-disabled,
.e-calendar .e-btn.e-today.e-flat.e-disabled:hover,
.e-calendar .e-btn.e-today.e-flat.e-disabled:active,
.e-calendar .e-btn.e-today.e-flat.e-disabled:focus,
.e-calendar .e-btn.e-today.e-flat.e-disabled:hover:active {
  background: transparent;
  border-color: transparent;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.26);
  cursor: default;
  opacity: 0.35;
  outline: none;
  pointer-events: none;
  -ms-touch-action: none;
      touch-action: none;
}

.e-content-placeholder.e-calendar.e-placeholder-calendar {
  background-size: 250px 336px;
  min-height: 336px;
}

.e-bigger .e-content-placeholder.e-calendar.e-placeholder-calendar,
.e-bigger.e-content-placeholder.e-calendar.e-placeholder-calendar {
  background-size: 300px 392px;
  min-height: 392px;
}

.e-calendar,
.e-bigger.e-small .e-calendar {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-shadow: none;
}

.e-calendar .e-date-icon-prev,
.e-calendar .e-date-icon-next,
.e-bigger.e-small .e-calendar .e-date-icon-prev,
.e-bigger.e-small .e-calendar .e-date-icon-next {
  color: rgba(0, 0, 0, 0.54);
}

.e-calendar th,
.e-bigger.e-small .e-calendar th {
  border-bottom: 0;
  color: rgba(0, 0, 0, 0.54);
}

.e-calendar .e-header,
.e-bigger.e-small .e-calendar .e-header {
  border-bottom: 0;
}

.e-calendar .e-header a span,
.e-bigger.e-small .e-calendar .e-header a span {
  border: none;
  color: rgba(0, 0, 0, 0.75);
}

.e-calendar .e-header .e-title,
.e-bigger.e-small .e-calendar .e-header .e-title {
  color: rgba(0, 0, 0, 0.75);
}

.e-calendar .e-header .e-title:hover,
.e-bigger.e-small .e-calendar .e-header .e-title:hover {
  color: rgba(0, 0, 0, 0.75);
  cursor: pointer;
  text-decoration: none;
}

.e-calendar .e-header .e-prev:hover > span,
.e-calendar .e-header .e-next:hover > span,
.e-bigger.e-small .e-calendar .e-header .e-prev:hover > span,
.e-bigger.e-small .e-calendar .e-header .e-next:hover > span {
  border: none;
  color: rgba(0, 0, 0, 0.75);
  cursor: pointer;
}

.e-calendar .e-header .e-prev:hover,
.e-calendar .e-header .e-next:hover,
.e-bigger.e-small .e-calendar .e-header .e-prev:hover,
.e-bigger.e-small .e-calendar .e-header .e-next:hover {
  background: none;
}

.e-calendar .e-header .e-prev:active,
.e-calendar .e-header .e-next:active,
.e-bigger.e-small .e-calendar .e-header .e-prev:active,
.e-bigger.e-small .e-calendar .e-header .e-next:active {
  background: #fff;
  color: #fff;
}

.e-calendar .e-header button.e-prev:active span,
.e-calendar .e-header button.e-next:active span,
.e-bigger.e-small .e-calendar .e-header button.e-prev:active span,
.e-bigger.e-small .e-calendar .e-header button.e-next:active span {
  border: none;
  color: rgba(0, 0, 0, 0.75);
}

.e-calendar .e-header.e-decade .e-title,
.e-bigger.e-small .e-calendar .e-header.e-decade .e-title {
  color: rgba(0, 0, 0, 0.87);
  cursor: default;
}

.e-calendar .e-header .e-next.e-disabled span,
.e-calendar .e-header .e-prev.e-disabled span,
.e-bigger.e-small .e-calendar .e-header .e-next.e-disabled span,
.e-bigger.e-small .e-calendar .e-header .e-prev.e-disabled span {
  color: rgba(0, 0, 0, 0.87);
  font-weight: normal;
}

.e-calendar .e-header .e-next.e-disabled,
.e-calendar .e-header .e-prev.e-disabled,
.e-bigger.e-small .e-calendar .e-header .e-next.e-disabled,
.e-bigger.e-small .e-calendar .e-header .e-prev.e-disabled {
  opacity: 0.35;
}

.e-calendar .e-content.e-decade tr:first-child .e-cell:first-child span.e-day,
.e-calendar .e-content.e-decade tr:last-child .e-cell:last-child span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-decade tr:first-child .e-cell:first-child span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-decade tr:last-child .e-cell:last-child span.e-day {
  color: rgba(0, 0, 0, 0.38);
}

.e-calendar .e-content.e-decade tr:first-child .e-cell:first-child.e-selected span.e-day,
.e-calendar .e-content.e-decade tr:last-child .e-cell:last-child.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-decade tr:first-child .e-cell:first-child.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-decade tr:last-child .e-cell:last-child.e-selected span.e-day {
  color: #fff;
}

.e-calendar .e-content.e-decade tr:first-child .e-cell.e-disabled:first-child span.e-day,
.e-calendar .e-content.e-decade tr:last-child .e-cell.e-disabled:last-child span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-decade tr:first-child .e-cell.e-disabled:first-child span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-decade tr:last-child .e-cell.e-disabled:last-child span.e-day {
  color: rgba(0, 0, 0, 0.87);
}

.e-calendar .e-content.e-year td:hover span.e-day,
.e-calendar .e-content.e-decade td:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-year td:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-decade td:hover span.e-day {
  background-color: #eee;
}

.e-calendar .e-content.e-year td.e-selected:hover span.e-day,
.e-calendar .e-content.e-decade td.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-year td.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-decade td.e-selected:hover span.e-day {
  background-color: #e3165b;
}

.e-calendar .e-content.e-year td > span.e-day,
.e-calendar .e-content.e-decade td > span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-year td > span.e-day,
.e-bigger.e-small .e-calendar .e-content.e-decade td > span.e-day {
  background: #fff;
}

.e-calendar .e-content .e-week-number span,
.e-bigger.e-small .e-calendar .e-content .e-week-number span {
  color: rgba(0, 0, 0, 0.54);
}

.e-calendar .e-content td.e-focused-date span.e-day,
.e-calendar .e-content td.e-focused-date:hover span.e-day,
.e-calendar .e-content td.e-focused-date:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date:focus span.e-day {
  background: #eee;
  border: none;
  border-radius: 50%;
}

.e-calendar .e-content td.e-focused-date:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date:hover span.e-day {
  background-color: #eee;
  border: none;
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.87);
}

.e-calendar .e-content td.e-today span.e-day,
.e-calendar .e-content td.e-focused-date.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day {
  background: none;
  border: 1px solid #e3165b;
  border-radius: 50%;
  color: #e3165b;
}

.e-calendar .e-content td.e-focused-date.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today span.e-day {
  background: #eee;
  border: 1px solid #e3165b;
  color: #e3165b;
}

.e-calendar .e-content td.e-today:focus span.e-day,
.e-calendar .e-content td.e-focused-date.e-today:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:focus span.e-day {
  background-color: #eee;
  border: none;
  border-radius: 50%;
  color: #e3165b;
}

.e-calendar .e-content td.e-today:hover span.e-day,
.e-calendar .e-content td.e-focused-date.e-today:hover span.e-day,
.e-calendar .e-content td.e-focused-date.e-today:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-focused-date.e-today:focus span.e-day {
  background-color: #eee;
  border: 1px solid #e3165b;
  color: #e3165b;
}

.e-calendar .e-content td.e-today.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today.e-selected span.e-day {
  background-color: #e3165b;
  border: 1px solid #e3165b;
  box-shadow: inset 0 0 0 2px #fff;
  color: #fff;
}

.e-calendar .e-content td.e-today.e-selected:hover span.e-day,
.e-calendar .e-content td.e-selected:hover span.e-day,
.e-calendar .e-content td.e-selected.e-focused-date span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-today.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected.e-focused-date span.e-day {
  background-color: #b51148;
  color: #fff;
}

.e-calendar .e-content span,
.e-bigger.e-small .e-calendar .e-content span {
  color: rgba(0, 0, 0, 0.87);
}

.e-calendar .e-content .e-disabled span.e-day:hover,
.e-bigger.e-small .e-calendar .e-content .e-disabled span.e-day:hover {
  background: none;
  border: 0;
  color: rgba(0, 0, 0, 0.87);
}

.e-calendar .e-content .e-other-month:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content .e-other-month:hover span.e-day {
  color: rgba(0, 0, 0, 0.38);
}

.e-calendar .e-content .e-other-month span.e-day,
.e-calendar .e-content .e-other-month.e-today span.e-day,
.e-bigger.e-small .e-calendar .e-content .e-other-month span.e-day,
.e-bigger.e-small .e-calendar .e-content .e-other-month.e-today span.e-day {
  color: rgba(0, 0, 0, 0.38);
}

.e-calendar .e-content .e-other-month.e-today:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content .e-other-month.e-today:hover span.e-day {
  background-color: #eee;
  color: rgba(0, 0, 0, 0.38);
}

.e-calendar .e-content thead,
.e-bigger.e-small .e-calendar .e-content thead {
  background: none;
  border-bottom: 0;
}

.e-calendar .e-content td:hover span.e-day,
.e-calendar .e-content td:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td:focus span.e-day {
  background-color: #eee;
  border: none;
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.87);
}

.e-calendar .e-content td:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td:focus span.e-day {
  background-color: #eee;
  border: none;
  border-radius: 50%;
  color: rgba(0, 0, 0, 0.87);
}

.e-calendar .e-content td.e-disabled span.e-day,
.e-calendar .e-content td.e-disabled:hover span.e-day,
.e-calendar .e-content td.e-disabled:focus span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-disabled span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-disabled:hover span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-disabled:focus span.e-day {
  background: none;
  border: none;
  color: rgba(0, 0, 0, 0.87);
}

.e-calendar .e-content td.e-selected span.e-day,
.e-bigger.e-small .e-calendar .e-content td.e-selected span.e-day {
  background-color: #e3165b;
  border: none;
  border-radius: 50%;
  color: #fff;
}

.e-calendar .e-content .e-footer,
.e-bigger.e-small .e-calendar .e-content .e-footer {
  color: #e3165b;
}

.e-calendar.e-device .e-prev:hover,
.e-calendar.e-device .e-next:hover,
.e-calendar.e-device .e-prev:active,
.e-calendar.e-device .e-next:active,
.e-calendar.e-device .e-prev:focus,
.e-calendar.e-device .e-next:focus,
.e-bigger.e-small .e-calendar.e-device .e-prev:hover,
.e-bigger.e-small .e-calendar.e-device .e-next:hover,
.e-bigger.e-small .e-calendar.e-device .e-prev:active,
.e-bigger.e-small .e-calendar.e-device .e-next:active,
.e-bigger.e-small .e-calendar.e-device .e-prev:focus,
.e-bigger.e-small .e-calendar.e-device .e-next:focus {
  background: none;
}

.e-calendar.e-device button.e-prev:active span,
.e-calendar.e-device button.e-next:active span,
.e-bigger.e-small .e-calendar.e-device button.e-prev:active span,
.e-bigger.e-small .e-calendar.e-device button.e-next:active span {
  color: rgba(0, 0, 0, 0.54);
}

.e-small.e-calendar .e-header .e-title,
.e-small .e-calendar .e-header .e-title {
  color: rgba(0, 0, 0, 0.87);
}

.e-zoomin {
  animation: animatezoom .3s;
}

@keyframes animatezoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes e-input-ripple {
  100% {
    opacity: 0;
    transform: scale(4);
  }
}

@keyframes slideTopUp {
  from {
    transform: translate3d(0, 0, 0) scale(1);
  }
  to {
    transform: translate3d(0, 0, 0) scale(1);
  }
}

/*! component icons */
.e-time-wrapper .e-time-icon.e-icons::before,
*.e-control-wrapper.e-time-wrapper .e-time-icon.e-icons::before {
  content: '\e20c';
}

.e-time-wrapper.e-outline .e-input-group-icon.e-time-icon,
.e-time-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-time-icon {
  min-height: 0;
  min-width: 20px;
}

.e-bigger .e-time-wrapper.e-outline .e-input-group-icon.e-time-icon,
.e-time-wrapper.e-control-wrapper.e-bigger.e-outline .e-input-group-icon.e-time-icon,
.e-bigger .e-time-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-time-icon {
  min-height: 0;
  min-width: 24px;
}

.e-small .e-time-wrapper.e-outline .e-input-group-icon.e-time-icon,
.e-time-wrapper.e-control-wrapper.e-small.e-outline .e-input-group-icon.e-time-icon,
.e-small .e-time-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-time-icon {
  min-height: 0;
  min-width: 18px;
}

.e-bigger.e-small .e-outline.e-time-wrapper .e-input-group-icon.e-time-icon,
.e-bigger.e-small.e-outline.e-time-wrapper .e-input-group-icon.e-time-icon,
.e-time-wrapper.e-control-wrapper.e-bigger.e-small.e-outline .e-input-group-icon.e-time-icon,
.e-bigger.e-small .e-time-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-time-icon {
  min-height: 0;
  min-width: 20px;
}

.e-input-group.e-control-wrapper.e-time-wrapper.e-non-edit.e-input-focus .e-input:focus ~ .e-clear-icon,
.e-float-input.e-control-wrapper.e-input-group.e-time-wrapper.e-non-edit.e-input-focus input:focus ~ .e-clear-icon {
  display: -ms-flexbox;
  display: flex;
}

.e-time-wrapper,
*.e-control-wrapper.e-time-wrapper {
  -webkit-tap-highlight-color: transparent;
}

.e-time-wrapper .e-time-icon.e-icons,
*.e-control-wrapper.e-time-wrapper .e-time-icon.e-icons {
  font-size: 16px;
  min-height: 20px;
  min-width: 20px;
}

.e-time-wrapper .e-time-icon.e-icons.e-disabled,
*.e-control-wrapper.e-time-wrapper .e-time-icon.e-icons.e-disabled {
  pointer-events: none;
}

.e-time-wrapper span,
*.e-control-wrapper.e-time-wrapper span {
  cursor: pointer;
}

.e-timepicker.e-time-modal {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  opacity: .5;
  pointer-events: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.e-timepicker.e-popup {
  border-style: solid;
  border-width: 1px;
  overflow: auto;
}

.e-timepicker.e-popup .e-content {
  position: relative;
}

.e-timepicker.e-popup .e-list-parent.e-ul {
  margin: 0;
  padding: 0 0;
}

.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item {
  cursor: default;
  font-size: 13px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
}

.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item.e-hover {
  cursor: pointer;
}

.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item {
  line-height: 36px;
  text-indent: 16px;
}

.e-bigger .e-time-wrapper .e-time-icon.e-icons,
*.e-bigger.e-time-wrapper .e-time-icon.e-icons,
*.e-bigger .e-control-wrapper .e-time-wrapper .e-time-icon.e-icons,
*.e-control-wrapper.e-bigger.e-time-wrapper .e-time-icon.e-icons {
  font-size: 20px;
  min-height: 22px;
  min-width: 22px;
}

.e-bigger .e-timepicker.e-popup .e-list-parent.e-ul,
*.e-bigger.e-timepicker.e-popup .e-list-parent.e-ul {
  padding: 0 0;
}

.e-bigger .e-timepicker.e-popup .e-list-parent.e-ul .e-list-item,
*.e-bigger.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item {
  font-size: 14px;
  line-height: 48px;
  text-indent: 16px;
}

.e-small .e-timepicker.e-popup .e-list-parent.e-ul .e-list-item,
*.e-small.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item {
  font-size: 12px;
  line-height: 26px;
  text-indent: 12px;
}

.e-small .e-time-wrapper .e-time-icon.e-icons,
*.e-small.e-time-wrapper .e-time-icon.e-icons,
*.e-small .e-control-wrapper.e-time-wrapper .e-time-icon.e-icons,
*.e-control-wrapper.e-small.e-time-wrapper .e-time-icon.e-icons {
  font-size: 14px;
}

.e-small.e-bigger .e-timepicker.e-popup .e-list-parent.e-ul .e-list-item,
*.e-small.e-bigger.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item {
  font-size: 13px;
  line-height: 40px;
  text-indent: 16px;
}

.e-small.e-bigger .e-time-wrapper .e-time-icon.e-icons,
*.e-small.e-bigger.e-time-wrapper .e-time-icon.e-icons,
*.e-small.bigger .e-control-wrapper.e-time-wrapper .e-time-icon.e-icons,
*.e-control-wrapper.e-small.bigger.e-time-wrapper .e-time-icon.e-icons {
  font-size: 18px;
}

.e-content-placeholder.e-timepicker.e-placeholder-timepicker {
  background-size: 250px 33px;
  min-height: 33px;
}

.e-bigger .e-content-placeholder.e-timepicker.e-placeholder-timepicker,
.e-bigger.e-content-placeholder.e-timepicker.e-placeholder-timepicker {
  background-size: 250px 40px;
  min-height: 40px;
}

.e-time-overflow {
  overflow: hidden;
}

.e-timepicker-mob-popup-wrap {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  height: 100%;
  -ms-flex-pack: center;
      justify-content: center;
  left: 0;
  max-height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1002;
}

.e-timepicker-mob-popup-wrap .e-timepicker.e-popup.e-lib.e-control.e-popup-open {
  left: 0 !important;
  position: relative;
  top: 0 !important;
}

/*! timepicker theme */
.e-time-wrapper .e-input-group-icon.e-icons.e-active {
  color: #e3165b;
}

.e-time-wrapper.e-input-group:not(.e-disabled) .e-input-group-icon.e-active:active {
  color: #e3165b;
}

.e-timepicker.e-popup {
  border: none;
  border-radius: 2px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.e-timepicker.e-popup .e-list-parent.e-ul {
  background-color: #fff;
}

.e-timepicker.e-popup .e-list-parent.e-ul li.e-list-item {
  border: none;
  color: rgba(0, 0, 0, 0.87);
}

.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item.e-disabled {
  color: rgba(0, 0, 0, 0.38);
  opacity: 1;
  pointer-events: none;
  -ms-touch-action: none;
      touch-action: none;
}

.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item.e-hover,
.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item.e-navigation,
.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item:focus {
  background-color: #eee;
  border: none;
  color: #000;
}

.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item.e-active {
  background-color: #eee;
  color: #e3165b;
}

.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item.e-active.e-hover {
  background-color: #eee;
  color: #e3165b;
}

.e-small .e-timepicker.e-popup .e-list-parent.e-ul .e-list-item,
*.e-small.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item,
.e-bigger.e-small .e-timepicker.e-popup .e-list-parent.e-ul .e-list-item,
*.e-bigger.e-small.e-timepicker.e-popup .e-list-parent.e-ul .e-list-item {
  color: #000;
}

@keyframes e-input-ripple {
  100% {
    opacity: 0;
    transform: scale(4);
  }
}

@keyframes slideTopUp {
  from {
    transform: translate3d(0, 0, 0) scale(1);
  }
  to {
    transform: translate3d(0, 0, 0) scale(1);
  }
}

/*! calendar material theme variables */
/*! component icons */
.e-input-group-icon.e-date-icon,
*.e-control-wrapper .e-input-group-icon.e-date-icon {
  font-size: 16px;
  margin: 0 0 4px 0;
  min-height: 24px;
  min-width: 24px;
  outline: none;
}

.e-input-group-icon.e-date-icon::before,
*.e-control-wrapper .e-input-group-icon.e-date-icon::before {
  content: '\e901';
  font-family: 'e-icons';
}

.e-input-group-icon.e-date-icon:focus,
*.e-control-wrapper .e-input-group-icon.e-date-icon:focus {
  background: #eee;
  border-radius: 50%;
}

.e-bigger .e-input-group-icon.e-date-icon,
*.e-control-wrapper.e-bigger .e-input-group-icon.e-date-icon,
*.e-bigger .e-control-wrapper .e-input-group-icon.e-date-icon {
  font-size: 20px;
  margin: 5px 0 6px 0;
  min-height: 26px;
  min-width: 26px;
}

.e-bigger .e-input-group-icon.e-date-icon::before,
*.e-control-wrapper.e-bigger .e-input-group-icon.e-date-icon::before,
*.e-bigger .e-control-wrapper .e-input-group-icon.e-date-icon::before {
  content: '\e901';
  font-family: 'e-icons';
}

.e-bigger .e-input-group-icon.e-date-icon:focus,
*.e-control-wrapper.e-bigger .e-input-group-icon.e-date-icon:focus,
*.e-bigger .e-control-wrapper .e-input-group-icon.e-date-icon:focus {
  background: #eee;
  border-radius: 50%;
}

.e-small .e-input-group-icon.e-date-icon,
*.e-control-wrapper.e-small .e-input-group-icon.e-date-icon,
*.e-small .e-control-wrapper .e-input-group-icon.e-date-icon {
  font-size: 14px;
}

.e-small.e-bigger .e-input-group-icon.e-date-icon,
*.e-control-wrapper.e-small.e-bigger .e-input-group-icon.e-date-icon,
*.e-small.e-bigger .e-control-wrapper .e-input-group-icon.e-date-icon {
  font-size: 18px;
}

.e-date-wrapper.e-outline .e-input-group-icon.e-date-icon,
.e-date-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-date-icon {
  min-height: 0;
  min-width: 20px;
}

.e-bigger .e-date-wrapper.e-outline .e-input-group-icon.e-date-icon,
.e-date-wrapper.e-control-wrapper.e-bigger.e-outline .e-input-group-icon.e-date-icon,
.e-bigger .e-date-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-date-icon {
  min-height: 0;
  min-width: 24px;
}

.e-small .e-date-wrapper.e-outline .e-input-group-icon.e-date-icon,
.e-date-wrapper.e-control-wrapper.e-small.e-outline .e-input-group-icon.e-date-icon,
.e-small .e-date-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-date-icon {
  min-height: 0;
  min-width: 18px;
}

.e-bigger.e-small .e-outline.e-date-wrapper .e-input-group-icon.e-date-icon,
.e-bigger.e-small.e-outline.e-date-wrapper .e-input-group-icon.e-date-icon,
.e-date-wrapper.e-control-wrapper.e-bigger.e-small.e-outline .e-input-group-icon.e-date-icon,
.e-bigger.e-small .e-date-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-date-icon {
  min-height: 0;
  min-width: 20px;
}

.e-input-group.e-control-wrapper.e-date-wrapper.e-non-edit.e-input-focus .e-input:focus ~ .e-clear-icon,
.e-float-input.e-control-wrapper.e-input-group.e-date-wrapper.e-non-edit.e-input-focus input:focus ~ .e-clear-icon {
  display: -ms-flexbox;
  display: flex;
}

.e-date-wrapper:not(.e-filled) .e-date-icon.e-icons {
  box-sizing: border-box;
}

.e-datepicker .e-calendar .e-content table tbody tr.e-month-hide:last-child {
  display: none;
}

.e-datepicker.e-popup-wrapper {
  border-radius: 0;
  overflow-y: hidden;
  pointer-events: auto;
}

.e-datepicker.e-date-modal {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  opacity: .5;
  pointer-events: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.e-datepicker .e-model-header {
  background-color: #e3165b;
  color: #fff;
  cursor: default;
  display: block;
  padding: 10px 10px 10px 15px;
}

.e-datepicker .e-model-header .e-model-year {
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  margin: 0;
}

.e-datepicker .e-model-month,
.e-datepicker .e-model-day {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  margin: 0;
}

.e-date-overflow {
  overflow: hidden !important;
}

.e-datepick-mob-popup-wrap {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  height: 100%;
  -ms-flex-pack: center;
      justify-content: center;
  left: 0;
  max-height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1002;
}

.e-datepick-mob-popup-wrap .e-datepicker.e-popup-wrapper.e-lib.e-popup.e-control.e-popup-open {
  position: relative;
  top: 0 !important;
  left: 0 !important;
}

.e-content-placeholder.e-datepicker.e-placeholder-datepicker {
  background-size: 250px 33px;
  min-height: 33px;
}

.e-bigger .e-content-placeholder.e-datepicker.e-placeholder-datepicker,
.e-bigger.e-content-placeholder.e-datepicker.e-placeholder-datepicker {
  background-size: 250px 40px;
  min-height: 40px;
}

@media screen and (orientation: landscape) and (max-height: 360px) {
  .e-datepicker .e-calendar.e-device .e-month table tbody {
    display: inline-block;
    height: 130px;
    overflow: auto;
  }
}

.e-datepicker.e-popup-wrapper,
.e-bigger.e-small .e-datepicker.e-popup-wrapper {
  border: none;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.e-datepicker .e-calendar,
.e-bigger.e-small .e-datepicker .e-calendar {
  background-color: #fff;
  border: none;
}

*.e-input-group.e-date-wrapper.e-dateinput-active:active:not(.e-success):not(.e-warning):not(.e-error) {
  border: none;
}

.e-date-wrapper span.e-input-group-icon.e-date-icon.e-icons.e-active {
  color: #e3165b;
}

.e-date-wrapper span.e-input-group-icon.e-date-icon.e-icons.e-active {
  color: #e3165b;
}

@keyframes e-input-ripple {
  100% {
    opacity: 0;
    transform: scale(4);
  }
}

@keyframes slideTopUp {
  from {
    transform: translate3d(0, 0, 0) scale(1);
  }
  to {
    transform: translate3d(0, 0, 0) scale(1);
  }
}

/*! calendar material theme variables */
/*! component icons */
.e-input-group-icon.e-range-icon,
*.e-control-wrapper .e-input-group-icon.e-range-icon {
  font-size: 16px;
  margin: 0 0 4px 0;
  min-height: 24px;
  min-width: 24px;
  outline: none;
}

.e-input-group-icon.e-range-icon::before,
*.e-control-wrapper .e-input-group-icon.e-range-icon::before {
  content: '\e245';
  font-family: 'e-icons';
}

.e-input-group-icon.e-range-icon:focus,
*.e-control-wrapper .e-input-group-icon.e-range-icon:focus {
  background: #eee;
  border-radius: 50%;
}

.e-daterangepicker .e-calendar .e-header .e-date-icon-next::before {
  content: '\e913';
}

.e-daterangepicker .e-calendar .e-header .e-date-icon-prev::before {
  content: '\e921';
}

.e-daterangepicker .e-change-icon::before {
  content: '\e85f';
}

.e-daterangepicker .e-calendar.e-rtl .e-header .e-date-icon-next::before {
  content: '\e921';
}

.e-daterangepicker .e-calendar.e-rtl .e-header .e-date-icon-prev::before {
  content: '\e913';
}

.e-daterangepicker.e-rtl .e-start-end .e-change-icon::before {
  content: '\e85b';
}

.e-bigger .e-input-group-icon.e-range-icon,
.e-bigger.e-control-wrapper .e-input-group-icon.e-range-icon,
.e-bigger .e-control-wrapper .e-input-group-icon.e-range-icon {
  font-size: 20px;
  margin: 0 0 2px 0;
  min-height: 24px;
  min-width: 24px;
  outline: none;
}

.e-small .e-input-group-icon.e-range-icon,
*.e-control-wrapper.e-small .e-input-group-icon.e-range-icon,
*.e-small .e-control-wrapper .e-input-group-icon.e-range-icon {
  font-size: 14px;
}

.e-small.e-bigger .e-input-group-icon.e-range-icon,
*.e-control-wrapper.e-small.e-bigger .e-input-group-icon.e-range-icon,
*.e-small.e-bigger .e-control-wrapper .e-input-group-icon.e-range-icon {
  font-size: 18px;
}

.e-date-range-wrapper.e-outline .e-input-group-icon.e-range-icon,
.e-date-range-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-range-icon {
  min-height: 0;
  min-width: 20px;
}

.e-bigger .e-date-range-wrapper.e-outline .e-input-group-icon.e-range-icon,
.e-date-range-wrapper.e-control-wrapper.e-bigger.e-outline .e-input-group-icon.e-range-icon,
.e-bigger .e-date-range-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-range-icon {
  min-height: 0;
  min-width: 24px;
}

.e-small .e-date-range-wrapper.e-outline .e-input-group-icon.e-range-icon,
.e-date-range-wrapper.e-control-wrapper.e-small.e-outline .e-input-group-icon.e-range-icon,
.e-small .e-date-range-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-range-icon {
  min-height: 0;
  min-width: 18px;
}

.e-bigger.e-small .e-outline.e-date-range-wrapper .e-input-group-icon.e-range-icon,
.e-bigger.e-small.e-outline.e-date-range-wrapper .e-input-group-icon.e-range-icon,
.e-date-range-wrapper.e-control-wrapper.e-bigger.e-small.e-outline .e-input-group-icon.e-range-icon,
.e-bigger.e-small .e-date-range-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-range-icon {
  min-height: 0;
  min-width: 20px;
}

/*! daterangepicker layout */
.e-input-group.e-control-wrapper.e-date-range-wrapper.e-non-edit.e-input-focus .e-input:focus ~ .e-clear-icon,
.e-float-input.e-control-wrapper.e-input-group.e-date-range-wrapper.e-non-edit.e-input-focus input:focus ~ .e-clear-icon {
  display: -ms-flexbox;
  display: flex;
}

.e-float-input.e-input-group.e-control-wrapper.e-date-range-wrapper .e-daterange-hidden,
.e-input-group.e-control-wrapper.e-date-range-wrapper .e-daterange-hidden,
.e-float-input.e-control-wrapper.e-date-range-wrapper .e-daterange-hidden,
.e-float-input.e-input-group.e-control-wrapper.e-date-range-wrapper.e-input-focus .e-daterange-hidden,
.e-input-group.e-control-wrapper.e-date-range-wrapper.e-input-focus .e-daterange-hidden,
.e-float-input.e-control-wrapper.e-date-range-wrapper.e-input-focus .e-daterange-hidden {
  border: 0;
  height: 0;
  margin: 0;
  padding: 0;
  text-indent: 0;
  visibility: hidden;
  width: 0;
}

.e-daterangepicker.e-popup,
.e-bigger.e-small .e-daterangepicker.e-popup {
  border: none;
  border-radius: 0;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  max-height: 500px;
  max-width: 730px;
}

.e-daterangepicker.e-popup.e-daterange-day-header-lg,
.e-bigger.e-small .e-daterangepicker.e-popup.e-daterange-day-header-lg {
  max-width: 100%;
}

.e-daterangepicker.e-popup.e-preset-wrapper,
.e-bigger.e-small .e-daterangepicker.e-popup.e-preset-wrapper {
  min-width: 625px;
}

.e-daterangepicker.e-popup.e-preset-wrapper .e-presets,
.e-bigger.e-small .e-daterangepicker.e-popup.e-preset-wrapper .e-presets {
  max-height: none;
}

.e-daterangepicker.e-popup .e-range-header,
.e-bigger.e-small .e-daterangepicker.e-popup .e-range-header {
  background: #fff;
  padding: 16px 16px 0 16px;
  width: auto;
}

.e-daterangepicker.e-popup .e-range-header .e-start-label,
.e-daterangepicker.e-popup .e-range-header .e-end-label,
.e-bigger.e-small .e-daterangepicker.e-popup .e-range-header .e-start-label,
.e-bigger.e-small .e-daterangepicker.e-popup .e-range-header .e-end-label {
  cursor: default;
  display: inline-block;
  font-size: 18px;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  white-space: nowrap;
  width: 48%;
}

.e-daterangepicker.e-popup .e-range-header .e-change-icon,
.e-bigger.e-small .e-daterangepicker.e-popup .e-range-header .e-change-icon {
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  width: 4%;
}

.e-daterangepicker.e-popup .e-range-header .e-day-span,
.e-bigger.e-small .e-daterangepicker.e-popup .e-range-header .e-day-span {
  direction: ltr;
  font-size: 13px;
  height: 14px;
  margin: 0 0 15px 0;
  text-align: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100%;
}

.e-daterangepicker.e-popup .e-range-header .e-start-end,
.e-bigger.e-small .e-daterangepicker.e-popup .e-range-header .e-start-end {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  height: 35px;
}

.e-daterangepicker.e-popup .e-separator,
.e-bigger.e-small .e-daterangepicker.e-popup .e-separator {
  height: 1px;
  margin: 0 16px;
}

.e-daterangepicker.e-popup .e-calendar,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar {
  border: none;
  margin: 0;
  padding: 0;
}

.e-daterangepicker.e-popup .e-calendar .e-content table,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-content table {
  padding: 0 10px 10px;
}

.e-daterangepicker.e-popup .e-calendar .e-header .e-title,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-header .e-title {
  cursor: pointer;
  float: none;
  font-weight: normal;
  line-height: 36px;
  margin-left: 0;
  width: auto;
}

.e-daterangepicker.e-popup .e-calendar .e-header.e-month, .e-daterangepicker.e-popup .e-calendar .e-header.e-year, .e-daterangepicker.e-popup .e-calendar .e-header.e-decade,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-header.e-month,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-header.e-year,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-header.e-decade {
  padding: 10px 5px 0 5px;
}

.e-daterangepicker.e-popup .e-calendar .e-header .e-next,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-header .e-next {
  float: right;
}

.e-daterangepicker.e-popup .e-calendar .e-header .e-prev,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-header .e-prev {
  float: left;
}

.e-daterangepicker.e-popup .e-calendar .e-header .e-next,
.e-daterangepicker.e-popup .e-calendar .e-header .e-prev,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-header .e-next,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-header .e-prev {
  height: 36px;
  width: 36px;
}

.e-daterangepicker.e-popup .e-calendar .e-header .e-next span,
.e-daterangepicker.e-popup .e-calendar .e-header .e-prev span,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-header .e-next span,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-header .e-prev span {
  padding: 10px;
}

.e-daterangepicker.e-popup .e-calendar .e-start-date.e-selected.e-range-hover,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-start-date.e-selected.e-range-hover {
  border-radius: 50% 0 0 50%;
}

.e-daterangepicker.e-popup .e-calendar .e-end-date.e-selected.e-range-hover,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-end-date.e-selected.e-range-hover {
  border-radius: 0 50% 50% 0;
}

.e-daterangepicker.e-popup .e-calendar .e-end-date.e-selected.e-range-hover span.e-day,
.e-daterangepicker.e-popup .e-calendar .e-start-date.e-selected.e-range-hover span.e-day,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-end-date.e-selected.e-range-hover span.e-day,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-start-date.e-selected.e-range-hover span.e-day {
  border: none;
}

.e-daterangepicker.e-popup .e-footer,
.e-bigger.e-small .e-daterangepicker.e-popup .e-footer {
  -ms-flex-align: center;
      align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  clear: both;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row-reverse;
      flex-direction: row-reverse;
  height: 48px;
}

.e-daterangepicker.e-popup .e-footer .e-btn,
.e-bigger.e-small .e-daterangepicker.e-popup .e-footer .e-btn {
  font-weight: 500;
  height: 27px;
  line-height: 27px;
  overflow: hidden;
  padding: 0 16px;
  text-overflow: ellipsis;
}

.e-daterangepicker.e-popup .e-footer .e-btn.e-apply,
.e-bigger.e-small .e-daterangepicker.e-popup .e-footer .e-btn.e-apply {
  margin: 0 8px 0 8px;
}

.e-daterangepicker.e-popup .e-date-range-container,
.e-bigger.e-small .e-daterangepicker.e-popup .e-date-range-container {
  float: left;
}

.e-daterangepicker.e-popup .e-date-range-container.e-range-border,
.e-bigger.e-small .e-daterangepicker.e-popup .e-date-range-container.e-range-border {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.e-daterangepicker.e-popup .e-calendar-container,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar-container {
  display: -ms-flexbox;
  display: flex;
}

.e-daterangepicker.e-popup .e-calendar-container .e-left-container,
.e-daterangepicker.e-popup .e-calendar-container .e-right-container,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar-container .e-left-container,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar-container .e-right-container {
  float: left;
}

.e-daterangepicker.e-popup .e-calendar-container .e-left-container,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar-container .e-left-container {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.e-daterangepicker.e-popup .e-presets,
.e-bigger.e-small .e-daterangepicker.e-popup .e-presets {
  max-height: 192px;
  overflow: auto;
  width: auto;
}

.e-daterangepicker.e-popup .e-presets .e-list-item,
.e-bigger.e-small .e-daterangepicker.e-popup .e-presets .e-list-item {
  border-radius: 0;
  cursor: pointer;
  line-height: 48px;
  overflow: hidden;
  padding: 0 24px;
  white-space: nowrap;
}

.e-daterangepicker.e-popup .e-presets .e-list-parent,
.e-bigger.e-small .e-daterangepicker.e-popup .e-presets .e-list-parent {
  margin: 0;
  max-width: 160px;
  padding: 0;
}

.e-daterangepicker.e-popup .e-presets .e-text-content,
.e-bigger.e-small .e-daterangepicker.e-popup .e-presets .e-text-content {
  line-height: 47px;
}

.e-daterangepicker.e-popup .e-presets .e-ul li.e-list-item,
.e-bigger.e-small .e-daterangepicker.e-popup .e-presets .e-ul li.e-list-item {
  font-size: 13px;
  height: 36px;
  line-height: 36px;
}

.e-daterangepicker.e-popup .e-hide-range,
.e-bigger.e-small .e-daterangepicker.e-popup .e-hide-range {
  display: none;
}

.e-daterangepicker.e-rtl .e-date-range-container,
.e-bigger.e-small .e-daterangepicker.e-rtl .e-date-range-container {
  float: right;
}

.e-daterangepicker.e-rtl .e-date-range-container.e-range-border,
.e-bigger.e-small .e-daterangepicker.e-rtl .e-date-range-container.e-range-border {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 0;
}

.e-daterangepicker.e-rtl .e-date-range-container .e-left-container,
.e-bigger.e-small .e-daterangepicker.e-rtl .e-date-range-container .e-left-container {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 0;
}

.e-daterangepicker.e-rtl .e-date-range-container .e-calendar .e-next,
.e-bigger.e-small .e-daterangepicker.e-rtl .e-date-range-container .e-calendar .e-next {
  float: left;
}

.e-daterangepicker.e-rtl .e-date-range-container .e-calendar .e-prev,
.e-bigger.e-small .e-daterangepicker.e-rtl .e-date-range-container .e-calendar .e-prev {
  float: right;
}

.e-daterangepicker.e-rtl .e-date-range-container .e-calendar .e-start-date.e-selected.e-range-hover,
.e-bigger.e-small .e-daterangepicker.e-rtl .e-date-range-container .e-calendar .e-start-date.e-selected.e-range-hover {
  border-radius: 0 50% 50% 0;
}

.e-daterangepicker.e-rtl .e-date-range-container .e-calendar .e-end-date.e-selected.e-range-hover,
.e-bigger.e-small .e-daterangepicker.e-rtl .e-date-range-container .e-calendar .e-end-date.e-selected.e-range-hover {
  border-radius: 50% 0 0 50%;
}

.e-daterangepicker.e-rtl .e-footer,
.e-bigger.e-small .e-daterangepicker.e-rtl .e-footer {
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.e-daterangepicker.e-rtl .e-footer .e-btn.e-cancel,
.e-bigger.e-small .e-daterangepicker.e-rtl .e-footer .e-btn.e-cancel {
  margin: 0 8px 0 8px;
}

.e-daterangepicker.e-rtl .e-footer .e-btn.e-apply,
.e-bigger.e-small .e-daterangepicker.e-rtl .e-footer .e-btn.e-apply {
  margin-left: 0;
}

.e-bigger .e-daterangepicker.e-range-modal,
*.e-device.e-daterangepicker.e-range-modal {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  opacity: .5;
  pointer-events: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.e-bigger.e-small .e-daterangepicker .e-calendar {
  max-width: 246px;
}

.e-bigger .e-daterangepicker.e-popup.e-preset-wrapper,
*.e-bigger.e-daterangepicker.e-popup.e-preset-wrapper,
*.e-device.e-daterangepicker.e-popup.e-preset-wrapper {
  max-width: 770px;
  min-width: auto;
}

.e-bigger .e-daterangepicker.e-popup.e-preset-wrapper .e-presets,
*.e-bigger.e-daterangepicker.e-popup.e-preset-wrapper .e-presets,
*.e-device.e-daterangepicker.e-popup.e-preset-wrapper .e-presets {
  max-height: none;
}

.e-bigger .e-daterangepicker.e-popup .e-range-header,
*.e-bigger.e-daterangepicker.e-popup .e-range-header,
*.e-device.e-daterangepicker.e-popup .e-range-header {
  width: auto;
}

.e-bigger .e-daterangepicker.e-popup .e-range-header .e-start-end,
*.e-bigger.e-daterangepicker.e-popup .e-range-header .e-start-end,
*.e-device.e-daterangepicker.e-popup .e-range-header .e-start-end {
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  height: 36px;
  -ms-flex-pack: center;
      justify-content: center;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100%;
}

.e-bigger .e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn,
.e-bigger .e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn,
*.e-bigger.e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn,
*.e-bigger.e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn,
*.e-device.e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn,
*.e-device.e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn {
  border: 1px solid #3f51b5;
  box-shadow: none;
  font-size: 14px;
  font-weight: 500;
  height: 36px;
  line-height: 34px;
  max-width: 116px;
  overflow: hidden;
  padding: 1px 6px;
  text-overflow: ellipsis;
  width: 50%;
}

.e-bigger .e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn,
*.e-bigger.e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn,
*.e-device.e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn {
  border-left: 0;
  border-radius: 0 2px 2px 0;
}

.e-bigger .e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn,
*.e-bigger.e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn,
*.e-device.e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn {
  border-radius: 2px 0 0 2px;
}

.e-bigger .e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn:hover,
.e-bigger .e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn:hover:not([disabled]),
*.e-bigger.e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn:hover,
*.e-bigger.e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn:hover:not([disabled]),
*.e-device.e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn:hover,
*.e-device.e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn:hover:not([disabled]) {
  box-shadow: none;
}

.e-bigger .e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn.e-active,
.e-bigger .e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn.e-active:active,
.e-bigger .e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn.e-active,
.e-bigger .e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn.e-active:active:not([disabled]),
.e-bigger .e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn.e-active:hover,
.e-bigger .e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn.e-active:hover,
*.e-bigger.e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn.e-active,
*.e-bigger.e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn.e-active:active,
*.e-bigger.e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn.e-active,
*.e-bigger.e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn.e-active:active:not([disabled]),
*.e-bigger.e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn.e-active:hover,
*.e-bigger.e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn.e-active:hover,
*.e-device.e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn.e-active,
*.e-device.e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn.e-active:active,
*.e-device.e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn.e-active,
*.e-device.e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn.e-active:active:not([disabled]),
*.e-device.e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn.e-active:hover,
*.e-device.e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn.e-active:hover {
  box-shadow: none;
}

.e-bigger .e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn,
.e-bigger .e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn,
*.e-bigger.e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn,
*.e-bigger.e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn,
*.e-device.e-daterangepicker.e-popup .e-range-header .e-start-end .e-start-btn,
*.e-device.e-daterangepicker.e-popup .e-range-header .e-start-end .e-end-btn {
  max-width: 141px;
}

.e-bigger .e-daterangepicker.e-popup .e-presets,
*.e-bigger.e-daterangepicker.e-popup .e-presets,
*.e-device.e-daterangepicker.e-popup .e-presets {
  max-height: 240px;
}

.e-bigger .e-daterangepicker.e-popup .e-presets.e-preset-wrapper,
*.e-bigger.e-daterangepicker.e-popup .e-presets.e-preset-wrapper,
*.e-device.e-daterangepicker.e-popup .e-presets.e-preset-wrapper {
  max-height: none;
}

.e-bigger .e-daterangepicker.e-popup .e-presets ul,
*.e-bigger.e-daterangepicker.e-popup .e-presets ul,
*.e-device.e-daterangepicker.e-popup .e-presets ul {
  max-width: none;
}

.e-bigger .e-daterangepicker.e-popup .e-presets ul li.e-list-item,
*.e-bigger.e-daterangepicker.e-popup .e-presets ul li.e-list-item,
*.e-device.e-daterangepicker.e-popup .e-presets ul li.e-list-item {
  font-size: 14px;
  height: 48px;
  line-height: 48px;
  padding: 0 16px;
}

.e-bigger .e-daterangepicker .e-calendar,
*.e-bigger.e-daterangepicker .e-calendar,
*.e-device.e-daterangepicker .e-calendar {
  max-width: 296px;
  padding: 0;
}

.e-bigger .e-daterangepicker .e-calendar .e-content table,
*.e-bigger.e-daterangepicker .e-calendar .e-content table,
*.e-device.e-daterangepicker .e-calendar .e-content table {
  padding: 0 15px 15px;
}

.e-bigger .e-daterangepicker .e-calendar .e-header .e-next,
.e-bigger .e-daterangepicker .e-calendar .e-header .e-prev,
*.e-bigger.e-daterangepicker .e-calendar .e-header .e-next,
*.e-bigger.e-daterangepicker .e-calendar .e-header .e-prev,
*.e-device.e-daterangepicker .e-calendar .e-header .e-next,
*.e-device.e-daterangepicker .e-calendar .e-header .e-prev {
  height: 48px;
  width: 48px;
}

.e-bigger .e-daterangepicker .e-calendar .e-header .e-next span,
.e-bigger .e-daterangepicker .e-calendar .e-header .e-prev span,
*.e-bigger.e-daterangepicker .e-calendar .e-header .e-next span,
*.e-bigger.e-daterangepicker .e-calendar .e-header .e-prev span,
*.e-device.e-daterangepicker .e-calendar .e-header .e-next span,
*.e-device.e-daterangepicker .e-calendar .e-header .e-prev span {
  padding: 15px;
}

.e-bigger .e-daterangepicker .e-calendar .e-header .e-title,
*.e-bigger.e-daterangepicker .e-calendar .e-header .e-title,
*.e-device.e-daterangepicker .e-calendar .e-header .e-title {
  cursor: pointer;
  line-height: 48px;
}

.e-bigger .e-daterangepicker .e-calendar .e-header.e-month, .e-bigger .e-daterangepicker .e-calendar .e-header.e-year, .e-bigger .e-daterangepicker .e-calendar .e-header.e-decade,
*.e-bigger.e-daterangepicker .e-calendar .e-header.e-month,
*.e-bigger.e-daterangepicker .e-calendar .e-header.e-year,
*.e-bigger.e-daterangepicker .e-calendar .e-header.e-decade,
*.e-device.e-daterangepicker .e-calendar .e-header.e-month,
*.e-device.e-daterangepicker .e-calendar .e-header.e-year,
*.e-device.e-daterangepicker .e-calendar .e-header.e-decade {
  padding: 10px 5px 0 5px;
}

.e-bigger .e-daterangepicker .e-footer,
*.e-bigger.e-daterangepicker .e-footer,
*.e-device.e-daterangepicker .e-footer {
  height: 58px;
}

.e-bigger .e-daterangepicker .e-footer .e-btn,
*.e-bigger.e-daterangepicker .e-footer .e-btn,
*.e-device.e-daterangepicker .e-footer .e-btn {
  height: 36px;
  line-height: 36px;
  overflow: hidden;
}

.e-bigger .e-daterangepicker .e-footer .e-btn.e-apply,
*.e-bigger.e-daterangepicker .e-footer .e-btn.e-apply,
*.e-device.e-daterangepicker .e-footer .e-btn.e-apply {
  margin: 0 8px 0 8px;
}

.e-bigger .e-daterangepicker.e-rtl.e-popup .e-range-header .e-start-end .e-end-btn,
*.e-bigger.e-daterangepicker.e-rtl.e-popup .e-range-header .e-start-end .e-end-btn,
*.e-device.e-daterangepicker.e-rtl.e-popup .e-range-header .e-start-end .e-end-btn {
  border: 1px solid #3f51b5;
  border-radius: 2px 0 0 2px;
  border-right: 0;
}

.e-bigger .e-daterangepicker.e-rtl.e-popup .e-range-header .e-start-end .e-start-btn,
*.e-bigger.e-daterangepicker.e-rtl.e-popup .e-range-header .e-start-end .e-start-btn,
*.e-device.e-daterangepicker.e-rtl.e-popup .e-range-header .e-start-end .e-start-btn {
  border-radius: 0 2px 2px 0;
}

.e-bigger .e-daterangepicker.e-rtl.e-popup .e-footer.e-btn.e-cancel,
*.e-bigger.e-daterangepicker.e-rtl.e-popup .e-footer.e-btn.e-cancel,
*.e-device.e-daterangepicker.e-rtl.e-popup .e-footer.e-btn.e-cancel {
  margin: 0 8px 0 8px;
}

.e-bigger .e-daterangepicker.e-rtl.e-popup .e-footer .e-btn.e-apply,
*.e-bigger.e-daterangepicker.e-rtl.e-popup .e-footer .e-btn.e-apply,
*.e-device.e-daterangepicker.e-rtl.e-popup .e-footer .e-btn.e-apply {
  margin-left: 0;
}

.e-bigger .e-daterangepicker.e-device.e-popup,
*.e-bigger.e-daterangepicker.e-device.e-popup,
*.e-device.e-daterangepicker.e-device.e-popup {
  max-width: 298px;
}

.e-bigger .e-daterangepicker.e-device.e-popup .e-range-header,
*.e-bigger.e-daterangepicker.e-device.e-popup .e-range-header,
*.e-device.e-daterangepicker.e-device.e-popup .e-range-header {
  margin: 20px 16px 0 16px;
  padding: 0;
}

.e-bigger .e-daterangepicker.e-device.e-popup .e-range-header .e-day-span,
*.e-bigger.e-daterangepicker.e-device.e-popup .e-range-header .e-day-span,
*.e-device.e-daterangepicker.e-device.e-popup .e-range-header .e-day-span {
  margin: 10px 0;
}

.e-small .e-daterangepicker.e-popup .e-range-header,
*.e-small.e-daterangepicker.e-popup .e-range-header {
  margin: 10px 10px 0 10px;
}

.e-small .e-daterangepicker.e-popup .e-range-header .e-start-label,
.e-small .e-daterangepicker.e-popup .e-range-header .e-end-label,
*.e-small.e-daterangepicker.e-popup .e-range-header .e-start-label,
*.e-small.e-daterangepicker.e-popup .e-range-header .e-end-label {
  font-size: 16px;
}

.e-small .e-daterangepicker.e-popup .e-range-header .e-change-icon,
*.e-small.e-daterangepicker.e-popup .e-range-header .e-change-icon {
  font-size: 12px;
}

.e-small .e-daterangepicker.e-popup .e-range-header .e-start-end,
*.e-small.e-daterangepicker.e-popup .e-range-header .e-start-end {
  height: 32px;
}

.e-small .e-daterangepicker.e-popup .e-range-header .e-day-span,
*.e-small.e-daterangepicker.e-popup .e-range-header .e-day-span {
  font-size: 12px;
  margin: 0 0 10px 0;
}

.e-small .e-daterangepicker.e-popup .e-range-header .e-separator,
*.e-small.e-daterangepicker.e-popup .e-range-header .e-separator {
  margin: 0 10px;
}

.e-small .e-daterangepicker.e-popup .e-footer .e-btn.e-apply,
*.e-small.e-daterangepicker.e-popup .e-footer .e-btn.e-apply {
  margin: 10px 10px 10px 8px;
}

.e-small .e-daterangepicker.e-popup.e-preset-wrapper .e-presets .e-list-parent.e-ul .e-list-item,
*.e-small.e-daterangepicker.e-popup.e-preset-wrapper .e-presets .e-list-parent.e-ul .e-list-item {
  font-size: 12px;
  height: 26px;
  line-height: 26px;
}

.e-range-overflow {
  overflow: hidden;
}

.e-daterangepick-mob-popup-wrap {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  height: 100%;
  -ms-flex-pack: center;
      justify-content: center;
  left: 0;
  max-height: 100%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1002;
}

.e-daterangepick-mob-popup-wrap .e-daterangepicker.e-popup.e-control.e-lib.e-device.e-popup-open {
  position: relative;
  top: 0 !important;
  left: 0 !important;
}

.e-content-placeholder.e-daterangepicker.e-placeholder-daterangepicker {
  background-size: 250px 33px;
  min-height: 33px;
}

.e-bigger .e-content-placeholder.e-daterangepicker.e-placeholder-daterangepicker,
.e-bigger.e-content-placeholder.e-daterangepicker.e-placeholder-daterangepicker {
  background-size: 250px 40px;
  min-height: 40px;
}

/*! daterangepicker theme */
.e-date-range-wrapper .e-input-group-icon.e-icons.e-active {
  color: #e3165b;
}

.e-date-range-wrapper.e-input-group:not(.e-disabled) .e-input-group-icon.e-active:active {
  color: #e3165b;
}

.e-daterangepicker.e-popup,
.e-bigger.e-small .e-daterangepicker.e-popup {
  background: #fff;
}

.e-daterangepicker.e-popup .e-calendar,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar {
  background-color: #fff;
}

.e-daterangepicker.e-popup .e-calendar .e-header .e-title,
.e-daterangepicker.e-popup .e-calendar .e-header .e-title:hover,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-header .e-title,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-header .e-title:hover {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none;
}

.e-daterangepicker.e-popup .e-calendar .e-content .e-range-hover,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-content .e-range-hover {
  background-color: #eee;
  color: rgba(0, 0, 0, 0.87);
}

.e-daterangepicker.e-popup .e-calendar .e-content.e-month .e-today.e-range-hover span,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-content.e-month .e-today.e-range-hover span {
  background-color: #eee;
  border: 1px solid #e3165b;
  color: #e3165b;
}

.e-daterangepicker.e-popup .e-calendar .e-content .e-range-hover span,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-content .e-range-hover span {
  background: #eee;
  border: none;
  color: rgba(0, 0, 0, 0.87);
}

.e-daterangepicker.e-popup .e-calendar .e-range-hover:not(.e-selected):hover span.e-day,
.e-daterangepicker.e-popup .e-calendar .e-range-hover.e-focused-date:not(.e-selected) span.e-day,
.e-daterangepicker.e-popup .e-calendar .e-range-hover.e-focused-date.e-today span.e-day,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-range-hover:not(.e-selected):hover span.e-day,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-range-hover.e-focused-date:not(.e-selected) span.e-day,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-range-hover.e-focused-date.e-today span.e-day {
  background-color: #c8c8c8;
  border: none;
  color: rgba(0, 0, 0, 0.87);
}

.e-daterangepicker.e-popup .e-calendar .e-range-hover.e-today:hover span.e-day,
.e-daterangepicker.e-popup .e-calendar .e-range-hover.e-focused-date.e-today span.e-day,
.e-daterangepicker.e-popup .e-calendar .e-range-hover.e-start-date.e-selected.e-today span.e-day,
.e-daterangepicker.e-popup .e-calendar .e-range-hover.e-end-date.e-selected.e-today span.e-day,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-range-hover.e-today:hover span.e-day,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-range-hover.e-focused-date.e-today span.e-day,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-range-hover.e-start-date.e-selected.e-today span.e-day,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-range-hover.e-end-date.e-selected.e-today span.e-day {
  border: 1px solid #e3165b;
}

.e-daterangepicker.e-popup .e-calendar .e-range-hover.e-selected.e-today:hover span.e-day,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-range-hover.e-selected.e-today:hover span.e-day {
  border: 1px solid #e3165b;
}

.e-daterangepicker.e-popup .e-calendar .e-content .e-other-month.e-range-hover,
.e-daterangepicker.e-popup .e-calendar .e-content .e-other-month.e-today.e-range-hover span,
.e-daterangepicker.e-popup .e-calendar .e-content .e-other-month.e-range-hover span,
.e-daterangepicker.e-popup .e-calendar .e-content .e-other-month.e-range-hover:hover span.e-day,
.e-daterangepicker.e-popup .e-calendar .e-content .e-other-month.e-selected,
.e-daterangepicker.e-popup .e-calendar .e-content .e-other-month.e-selected span,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-content .e-other-month.e-range-hover,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-content .e-other-month.e-today.e-range-hover span,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-content .e-other-month.e-range-hover span,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-content .e-other-month.e-range-hover:hover span.e-day,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-content .e-other-month.e-selected,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-content .e-other-month.e-selected span {
  background-color: #fff;
  border: none;
  color: rgba(0, 0, 0, 0.38);
}

.e-daterangepicker.e-popup .e-calendar .e-content .e-other-month.e-range-hover,
.e-daterangepicker.e-popup .e-calendar .e-content .e-other-month.e-selected,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-content .e-other-month.e-range-hover,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-content .e-other-month.e-selected {
  background-color: #fff;
  border: none;
  color: rgba(0, 0, 0, 0.38);
}

.e-daterangepicker.e-popup .e-calendar .e-end-date.e-selected.e-range-hover span.e-day,
.e-daterangepicker.e-popup .e-calendar .e-start-date.e-selected.e-range-hover span.e-day,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-end-date.e-selected.e-range-hover span.e-day,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-start-date.e-selected.e-range-hover span.e-day {
  background-color: #e3165b;
  color: #fff;
}

.e-daterangepicker.e-popup .e-calendar .e-start-date.e-selected.e-range-hover.e-today span.e-day,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-start-date.e-selected.e-range-hover.e-today span.e-day {
  background-color: #e3165b;
  color: #fff;
}

.e-daterangepicker.e-popup .e-calendar .e-other-month.e-selected span,
.e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-other-month.e-selected span {
  color: #fff;
}

.e-daterangepicker.e-popup .e-presets,
.e-bigger.e-small .e-daterangepicker.e-popup .e-presets {
  background-color: #fff;
  color: #000;
}

.e-daterangepicker.e-popup .e-presets .e-list-item.e-active,
.e-bigger.e-small .e-daterangepicker.e-popup .e-presets .e-list-item.e-active {
  background-color: #fff;
  color: #e3165b;
}

.e-daterangepicker.e-popup .e-presets .e-list-item.e-hover,
.e-bigger.e-small .e-daterangepicker.e-popup .e-presets .e-list-item.e-hover {
  background-color: #eee;
  color: rgba(0, 0, 0, 0.87);
}

.e-daterangepicker.e-popup .e-start-label,
.e-daterangepicker.e-popup .e-end-label,
.e-bigger.e-small .e-daterangepicker.e-popup .e-start-label,
.e-bigger.e-small .e-daterangepicker.e-popup .e-end-label {
  color: rgba(0, 0, 0, 0.87);
}

.e-daterangepicker.e-popup .e-change-icon,
.e-bigger.e-small .e-daterangepicker.e-popup .e-change-icon {
  color: rgba(0, 0, 0, 0.74);
}

.e-daterangepicker.e-popup .e-day-span,
.e-bigger.e-small .e-daterangepicker.e-popup .e-day-span {
  color: rgba(0, 0, 0, 0.54);
}

.e-daterangepicker.e-popup .e-separator,
.e-bigger.e-small .e-daterangepicker.e-popup .e-separator {
  background-color: rgba(0, 0, 0, 0.12);
}

.e-daterangepicker.e-popup .e-footer,
.e-bigger.e-small .e-daterangepicker.e-popup .e-footer {
  background-color: #fff;
}

.e-bigger .e-daterangepicker,
*.e-bigger.e-daterangepicker,
*.e-device.e-daterangepicker {
  background-color: #fff;
  padding: 0;
}

.e-bigger .e-daterangepicker .e-calendar th,
*.e-bigger.e-daterangepicker .e-calendar th,
*.e-device.e-daterangepicker .e-calendar th {
  color: rgba(0, 0, 0, 0.38);
}

.e-bigger .e-daterangepicker .e-start-btn,
.e-bigger .e-daterangepicker .e-end-btn,
*.e-bigger.e-daterangepicker .e-start-btn,
*.e-bigger.e-daterangepicker .e-end-btn,
*.e-device.e-daterangepicker .e-start-btn,
*.e-device.e-daterangepicker .e-end-btn {
  background: #fff;
}

.e-bigger .e-daterangepicker .e-start-btn.e-active,
.e-bigger .e-daterangepicker .e-start-btn.e-active:active,
.e-bigger .e-daterangepicker .e-end-btn.e-active,
.e-bigger .e-daterangepicker .e-end-btn.e-active:active:not([disabled]),
.e-bigger .e-daterangepicker .e-start-btn.e-active:hover,
.e-bigger .e-daterangepicker .e-end-btn.e-active:hover,
*.e-bigger.e-daterangepicker .e-start-btn.e-active,
*.e-bigger.e-daterangepicker .e-start-btn.e-active:active,
*.e-bigger.e-daterangepicker .e-end-btn.e-active,
*.e-bigger.e-daterangepicker .e-end-btn.e-active:active:not([disabled]),
*.e-bigger.e-daterangepicker .e-start-btn.e-active:hover,
*.e-bigger.e-daterangepicker .e-end-btn.e-active:hover,
*.e-device.e-daterangepicker .e-start-btn.e-active,
*.e-device.e-daterangepicker .e-start-btn.e-active:active,
*.e-device.e-daterangepicker .e-end-btn.e-active,
*.e-device.e-daterangepicker .e-end-btn.e-active:active:not([disabled]),
*.e-device.e-daterangepicker .e-start-btn.e-active:hover,
*.e-device.e-daterangepicker .e-end-btn.e-active:hover {
  background: #3f51b5;
  color: #fff;
}

@keyframes e-input-ripple {
  100% {
    opacity: 0;
    transform: scale(4);
  }
}

@keyframes slideTopUp {
  from {
    transform: translate3d(0, 0, 0) scale(1);
  }
  to {
    transform: translate3d(0, 0, 0) scale(1);
  }
}

/*! component icons */
.e-datetime-wrapper .e-time-icon.e-icons::before {
  content: '\e20c';
  font-family: 'e-icons';
}

.e-datetime-wrapper.e-outline .e-input-group-icon.e-date-icon,
.e-datetime-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-date-icon,
.e-datetime-wrapper.e-outline .e-input-group-icon.e-time-icon,
.e-datetime-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-time-icon {
  min-height: 0;
  min-width: 20px;
}

.e-bigger .e-datetime-wrapper.e-outline .e-input-group-icon.e-date-icon,
.e-datetime-wrapper.e-control-wrapper.e-bigger.e-outline .e-input-group-icon.e-date-icon,
.e-bigger .e-datetime-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-date-icon,
.e-bigger .e-datetime-wrapper.e-outline .e-input-group-icon.e-time-icon,
.e-datetime-wrapper.e-control-wrapper.e-bigger.e-outline .e-input-group-icon.e-time-icon,
.e-bigger .e-datetime-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-time-icon {
  min-height: 0;
  min-width: 24px;
}

.e-small .e-datetime-wrapper.e-outline .e-input-group-icon.e-date-icon,
.e-datetime-wrapper.e-control-wrapper.e-small.e-outline .e-input-group-icon.e-date-icon,
.e-small .e-datetime-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-date-icon,
.e-small .e-datetime-wrapper.e-outline .e-input-group-icon.e-time-icon,
.e-datetime-wrapper.e-control-wrapper.e-small.e-outline .e-input-group-icon.e-time-icon,
.e-small .e-datetime-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-time-icon {
  min-height: 0;
  min-width: 18px;
}

.e-bigger.e-small .e-outline.e-datetime-wrapper .e-input-group-icon.e-date-icon,
.e-bigger.e-small.e-outline.e-datetime-wrapper .e-input-group-icon.e-date-icon,
.e-datetime-wrapper.e-control-wrapper.e-bigger.e-small.e-outline .e-input-group-icon.e-date-icon,
.e-bigger.e-small .e-datetime-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-date-icon,
.e-bigger.e-small .e-outline.e-datetime-wrapper .e-input-group-icon.e-time-icon,
.e-bigger.e-small.e-outline.e-datetime-wrapper .e-input-group-icon.e-time-icon,
.e-datetime-wrapper.e-control-wrapper.e-bigger.e-small.e-outline .e-input-group-icon.e-time-icon,
.e-bigger.e-small .e-datetime-wrapper.e-control-wrapper.e-outline .e-input-group-icon.e-time-icon {
  min-height: 0;
  min-width: 20px;
}

.e-datetime-wrapper.e-filled .e-input-group-icon.e-time-icon,
.e-datetime-wrapper.e-control-wrapper.e-filled .e-input-group-icon.e-time-icon,
.e-rtl.e-datetime-wrapper.e-filled .e-input-group-icon.e-time-icon,
.e-rtl.e-datetime-wrapper.e-control-wrapper.e-filled .e-input-group-icon.e-time-icon,
.e-rtl .e-datetime-wrapper.e-filled .e-input-group-icon.e-time-icon,
.e-rtl .e-datetime-wrapper.e-control-wrapper.e-filled .e-input-group-icon.e-time-icon {
  margin: 0;
}

.e-input-group.e-control-wrapper.e-datetime-wrapper.e-non-edit.e-input-focus .e-input:focus ~ .e-clear-icon,
.e-float-input.e-control-wrapper.e-input-group.e-datetime-wrapper.e-non-edit.e-input-focus input:focus ~ .e-clear-icon {
  display: -ms-flexbox;
  display: flex;
}

.e-datetime-wrapper {
  -webkit-tap-highlight-color: transparent;
}

.e-datetime-wrapper .e-time-icon.e-icons::before {
  font-size: 16px;
}

.e-datetime-wrapper.e-control-wrapper {
  box-sizing: border-box;
}

.e-datetime-wrapper .e-time-icon.e-icons.e-disabled,
.e-datetime-wrapper .e-date-icon.e-icons.e-disabled {
  pointer-events: none;
}

.e-datetime-wrapper .e-clear-icon {
  box-sizing: content-box;
}

.e-datetime-wrapper span {
  cursor: pointer;
}

.e-datetime-wrapper .e-input-group-icon.e-date-icon,
.e-datetime-wrapper .e-input-group-icon.e-time-icon {
  font-size: 16px;
  margin: 0 0 4px;
  min-height: 24px;
  min-width: 24px;
  outline: none;
}

.e-datetime-wrapper .e-input-group-icon.e-time-icon {
  border: 0;
  border-style: none;
  margin: 0 0 4px 6px;
}

.e-datetime-wrapper:not(.e-outline).e-rtl .e-input-group-icon.e-time-icon {
  margin: 0 6px 4px 0;
}

.e-datetimepicker.e-time-modal {
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  left: 0;
  opacity: .5;
  pointer-events: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.e-datetimepicker.e-popup {
  border-style: solid;
  border-width: 1px;
  overflow: auto;
}

.e-datetimepicker.e-popup .e-content {
  position: relative;
}

.e-datetimepicker.e-popup .e-list-parent.e-ul {
  margin: 0;
  padding: 8px 0;
}

.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item {
  color: rgba(0, 0, 0, 0.87);
  cursor: default;
  font-size: 13px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
  width: 100%;
}

.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item.e-hover {
  cursor: pointer;
}

.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item {
  line-height: 36px;
  text-indent: 16px;
}

.e-bigger.e-datetime-wrapper .e-time-icon.e-icons::before,
*.e-bigger .e-datetime-wrapper .e-time-icon.e-icons::before {
  font-size: 20px;
}

.e-bigger.e-datetime-wrapper .e-input-group-icon.e-time-icon,
*.e-bigger .e-datetime-wrapper .e-input-group-icon.e-time-icon {
  margin: 5px 0 6px 8px;
  min-height: 26px;
  min-width: 26px;
}

.e-bigger.e-datetime-wrapper:not(.e-outline).e-rtl .e-input-group-icon.e-time-icon,
*.e-bigger .e-datetime-wrapper:not(.e-outline).e-rtl .e-input-group-icon.e-time-icon {
  margin: 5px 8px 6px 0;
}

.e-bigger .e-datetimepicker.e-popup .e-list-parent.e-ul,
*.e-bigger.e-datetimepicker.e-popup .e-list-parent.e-ul {
  padding: 8px 0;
}

.e-bigger .e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item,
*.e-bigger.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item {
  font-size: 14px;
  line-height: 48px;
  text-indent: 16px;
}

.e-small .e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item,
*.e-small.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item {
  font-size: 12px;
  line-height: 26px;
  text-indent: 12px;
}

.e-small.e-bigger .e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item,
*.e-small.e-bigger.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item {
  font-size: 13px;
  line-height: 40px;
  text-indent: 16px;
}

.e-small.e-datetime-wrapper .e-time-icon.e-icons::before,
*.e-small .e-datetime-wrapper .e-time-icon.e-icons::before {
  font-size: 14px;
}

.e-small.e-bigger.e-datetime-wrapper .e-time-icon.e-icons::before,
*.e-small.e-bigger .e-datetime-wrapper .e-time-icon.e-icons::before {
  font-size: 18px;
}

.e-content-placeholder.e-datetimepicker.e-placeholder-datetimepicker {
  background-size: 250px 33px;
  min-height: 33px;
}

.e-bigger .e-content-placeholder.e-datetimepicker.e-placeholder-datetimepicker,
.e-bigger.e-content-placeholder.e-datetimepicker.e-placeholder-datetimepicker {
  background-size: 250px 40px;
  min-height: 40px;
}

/*! datetimepicker theme */
.e-datetime-wrapper .e-input-group-icon.e-icons.e-active {
  color: #e3165b;
}

.e-datetime-wrapper.e-input-group:not(.e-disabled) .e-input-group-icon.e-active:active {
  color: #e3165b;
}

.e-datetimepicker.e-popup {
  border: none;
  border-radius: 2px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.e-datetimepicker.e-popup .e-list-parent.e-ul {
  background-color: #fff;
}

.e-datetimepicker.e-popup .e-list-parent.e-ul li.e-list-item {
  border: none;
  color: rgba(0, 0, 0, 0.87);
}

.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item.e-hover,
.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item.e-navigation,
.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item:focus {
  background-color: #eee;
  border: none;
  color: #000;
}

.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item.e-active {
  background-color: #eee;
  color: #e3165b;
}

.e-datetimepicker.e-popup .e-list-parent.e-ul .e-list-item.e-active.e-hover {
  background-color: #eee;
  color: #e3165b;
}
